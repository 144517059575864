export default function getQueryKeys(name: string) {
  const keys = {
    all: [name] as const,
    lists: (page = 0, rowsPerPage = 25) =>
      [...keys.all, "list", page, rowsPerPage] as const,
    list: (page = 0, rowsPerPage = 25, filters: any) =>
      [...keys.lists(page, rowsPerPage), filters] as const,
    details: () => [...keys.all, "detail"] as const,
    detail: (id: string) => [...keys.details(), id] as const,
    count: (filters: any) => [...keys.all, "count", filters] as const,
  };
  return keys;
}
