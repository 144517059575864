export const caseFoldNormalize = function (str: string) {
  return str.normalize("NFKC").toLowerCase();
};

export const getKeybwordArray_old = function (str: string) {
  return str.split(" ").map(caseFoldNormalize);
};

export function getKeywordArray(...strArray: (string | undefined)[]): string[] {
  return [
    ...new Set(
      strArray
        .join(" ")
        .replace(/[-/]/g, " ")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((w) => w.trim())
        .map(caseFoldNormalize)
        .filter((w) => w.length > 1)
    ),
  ];
}

export function getBOLKeywords(bol?: string) {
  if (bol && bol.indexOf(",") !== -1) {
    return bol.split(",").map((b) => b.trim());
  }
  if (bol && bol.indexOf("-") !== -1) {
    return bol.split("-").map((b) => b.trim());
  }
  return [];
}
