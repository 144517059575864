import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// components
import Logo from "components/Logo";

export default function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        py: 5,
        mt: 1,
        textAlign: "center",
        position: "relative",
        bgcolor: "background.default",
        ...props.sx,
      }}
    >
      <Container>
        {!props.hideLogo && <Logo sx={{ mb: 1, mx: "auto" }} />}
        <Typography variant="body2" color="text.secondary">
          {t("layout.copyright", { date: new Date().getFullYear() })}
        </Typography>
      </Container>
    </Box>
  );
}
