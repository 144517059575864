// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
export const ROOT_MY_ACCOUNT = "/account";

// ----------------------------------------------------------------------

export const MY_ACCOUNT = {
  root: ROOT_MY_ACCOUNT,
  settings: path(ROOT_MY_ACCOUNT, "/settings"),
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, "/sign-in"),
  signUp: path(ROOTS_AUTH, "/sign-up"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  unverifiedEmail: path(ROOTS_AUTH, "/unverified-email"),
  pending: path(ROOTS_AUTH, "/pending"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  urlFactory: {
    list: (name: string, stringParams?: Record<string, string>) => {
      return (
        path(ROOTS_DASHBOARD, "/" + name + "/list") +
        "?" +
        new URLSearchParams(stringParams)
      );
    },
  },
  general: {
    welcome: path(ROOTS_DASHBOARD, "/welcome"),
    orders: path(ROOTS_DASHBOARD, "/orders"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    locations: path(ROOTS_DASHBOARD, "/locations"),
    invoices: path(ROOTS_DASHBOARD, "/invoices"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  users: {
    root: path(ROOTS_DASHBOARD, "/users"),
    list: path(ROOTS_DASHBOARD, "/users/list"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/users/account"),
    remove: path(ROOTS_DASHBOARD, "/users/remove"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/users/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/users/${name}/view`),
  },
  profile: {
    edit: () => path(ROOTS_DASHBOARD, `/profile/edit`),
    view: () => path(ROOTS_DASHBOARD, `/profile`),
  },
  groups: {
    root: path(ROOTS_DASHBOARD, "/groups"),
    new: path(ROOTS_DASHBOARD, "/groups/new"),
    list: path(ROOTS_DASHBOARD, "/groups/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/groups/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/groups/${name}/view`),
  },
  locations: {
    root: path(ROOTS_DASHBOARD, "/locations"),
    new: path(ROOTS_DASHBOARD, "/locations/new"),
    list: path(ROOTS_DASHBOARD, "/locations/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/locations/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/locations/${name}/view`),
  },
  orders: {
    root: path(ROOTS_DASHBOARD, "/orders"),
    new: path(ROOTS_DASHBOARD, "/orders/new"),
    list: path(ROOTS_DASHBOARD, "/orders/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/orders/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/orders/${name}/view`),
    driver: (name: string) => path(ROOTS_DASHBOARD, `/orders/${name}/driver`),
  },
  transactions: {
    root: path(ROOTS_DASHBOARD, "/transactions"),
    new: path(ROOTS_DASHBOARD, "/transactions/new"),
    list: path(ROOTS_DASHBOARD, "/transactions/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/transactions/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/transactions/${name}/view`),
  },
  invoices: {
    root: path(ROOTS_DASHBOARD, "/invoices"),
    new: path(ROOTS_DASHBOARD, "/invoices/new"),
    list: path(ROOTS_DASHBOARD, "/invoices/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/invoices/${name}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoices/${id}/view`),
  },
  distributors: {
    root: path(ROOTS_DASHBOARD, "/distributors"),
    new: path(ROOTS_DASHBOARD, "/distributors/new"),
    list: path(ROOTS_DASHBOARD, "/distributors/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/distributors/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/distributors/${name}/view`),
  },
  sources: {
    root: path(ROOTS_DASHBOARD, "/sources"),
    new: path(ROOTS_DASHBOARD, "/sources/new"),
    list: path(ROOTS_DASHBOARD, "/sources/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/sources/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/sources/${name}/view`),
  },
  drivers: {
    root: path(ROOTS_DASHBOARD, "/drivers"),
    new: path(ROOTS_DASHBOARD, "/drivers/new"),
    list: path(ROOTS_DASHBOARD, "/drivers/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/drivers/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/drivers/${name}/view`),
  },
  products: {
    root: path(ROOTS_DASHBOARD, "/products"),
    new: path(ROOTS_DASHBOARD, "/products/new"),
    list: path(ROOTS_DASHBOARD, "/products/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/products/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/products/${name}/view`),
  },
  fleet: {
    root: path(ROOTS_DASHBOARD, "/fleet"),
    new: path(ROOTS_DASHBOARD, "/fleet/new"),
    list: path(ROOTS_DASHBOARD, "/fleet/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/fleet/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/fleet/${name}/view`),
  },
  trucking: {
    root: path(ROOTS_DASHBOARD, "/trucking"),
    trucks: {
      root: path(ROOTS_DASHBOARD, "/trucking/trucks"),
      new: path(ROOTS_DASHBOARD, "/trucking/trucks/new"),
      list: path(ROOTS_DASHBOARD, "/trucking/trucks/list"),
      edit: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/trucks/${name}/edit`),
      view: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/trucks/${name}/view`),
    },
    tankers: {
      root: path(ROOTS_DASHBOARD, "/trucking/tankers"),
      new: path(ROOTS_DASHBOARD, "/trucking/tankers/new"),
      list: path(ROOTS_DASHBOARD, "/trucking/tankers/list"),
      edit: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/tankers/${name}/edit`),
      view: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/tankers/${name}/view`),
    },
    reports: {
      root: path(ROOTS_DASHBOARD, "/trucking/reports"),
      new: path(ROOTS_DASHBOARD, "/trucking/reports/new"),
      list: path(ROOTS_DASHBOARD, "/trucking/reports/list"),
      edit: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/reports/${name}/edit`),
      view: (name: string) =>
        path(ROOTS_DASHBOARD, `/trucking/reports/${name}/view`),
    },
  },
};
