export enum Lang {
  ar = "ar",
  en = "en-US",
  fr = "fr-FR",
}

export const translations: Record<Lang, object> = {
  [Lang.ar]: {
    home: "الصفحة الرئيسية",
    "layout.company": "United Logistics Of Illinois",
    "layout.copyright": "© {{date}}",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "المستخدم",
    logout: "تسجيل خروج",
    "errors.general": "آسف، كان هناك خطأ!",
    "errors.required.fields": "الرجاء إكمال الحقول المطلوبة!",
    save: "حفظ",
    "file.format": "تنسيق الملف",
    back: "العودة",
    download: "تحميل",
    delete: "حذف",
    edit: "تحرير",
    restore: "استعادة",
    "edit.document.remove.existing.file": "إزالة الملف الحالي",
    "document.deleted.message":
      'تم حذف الوثيقة ، انقر فوق "استعادة" لاستعادتها',
    "delete.modal.title": "يرجى التأكيد",
    "delete.modal.body": "هل أنت متأكد من أنك تريد حذف الوثيقة؟",
    cancel: "إلغاء",
    "deleted.documents": "الوثائق المحذوفة",
    "welcome.user": "مرحبًا {{displayName}}!",
    ok: "موافق",
    "invalid.date": "تنسيق التاريخ غير صالح",
    "home.title": "ابحث عن الوثائق",
    search: "بحث",
    "no.result": "لم يتم العثور على نتائج ، يرجى محاولة استخدام كلمات مختلفة.",
    "deleted.documents.no.documents": "لا توجد وثائق",
    "drawer.advanced.search": "البحث المتقدم",
    "search.searchAll": "أدخل كلمة البحث",
    "advanced.search.title": "البحث المتقدم",
    "show.more": "عرض المزيد",
    "client.info.zg": "الزاوية الصوفية الغظفية",
    "client.info.presidency": "الرئاسة",
    "client.info.upr": "الاتحاد من اجل الجمهورية",
    attachments: "المرفقات",
    "no.attachments": "لا توجد مرفقات",
    "add.attachments": "أضف مرفقات",
    "add.attachments.title": "أضف مرفقات",
    "back.to.document": "رجوع إلى الوثيقة",
    "alerts.attachments.saved": "تم حفظ المرفقات بنجاح",
    "click.to.download": "اضغط للتحميل",
    "client.info.dev": "تطبيق تجريبي",
    signin: "تسجيل الدخول",
    "email.address": "البريد الإلكتروني",
    password: "كلمه السر",
    "create.account.link": "ليس لديك حساب؟ اشتراك",
    "forgot.password": "هل نسيت كلمة السر؟",
    "already.have.an.account": "هل لديك حساب؟ تسجيل الدخول",
    "sign.up": "اشتراك",
    "confirm.password": "تأكيد كلمة السر",
    "last.name": "اسم العائلة",
    "first.name": "الاسم",
    "confirm.sign.up": "قم بتأكيد التسجيل",
    "sign.up.code": "الرمز",
    InvalidPasswordException: "كلمة السر لا تتوافق مع السياسة.",
    CodeMismatchException:
      "رمز التحقق الذي قدمته غير صالح ، يرجى المحاولة مرة أخرى.",
    "request.new.password.code": "أرسل لي رمز التحقق",
    "check.email.for.code":
      "يرجى الاطلاع على بريدك الإلكتروني للحصول على رمز استعادة كلمة السر وإدخاله أدناه.",
    "create.new.password": "أنشئ كلمة سر جديدة",
    "password.rest.instructions":
      "يرجى تقديم عنوان بريدك الإلكتروني ، وسوف نرسل لك رمز التحقق لإعادة تعيين كلمة السر الخاصة بك.",
    "confirm.sign.up.instructions":
      "يرجى الاطلاع على بريدك الإلكتروني للحصول على رمز التحقق وإدخاله أدناه ، لإكمال تسجيلك.",
    "all.fields.required": "جميع الحقول مطلوبة.",
    "password.mismatch": "يرجى التحقق مرة أخرى من كلمات السر التي أدخلتها.",
    "password.instructions":
      "متطلبات كلمة المرور: بحد أدنى 8 أحرف ، ورقم واحد على الأقل ، وحرف واحد كبير ، وحرف واحد صغير.",
    "email.required": "مطلوب عنوان البريد الإلكتروني.",
    UsernameExistsException:
      "يوجد حساب مرتبط بالبريد الإلكتروني المحدد بالفعل ، يرجى تسجيل الدخول.",
    UserNotFoundException: "المستخدم غير موجود.",
    NotAuthorizedException: "اسم المستخدم أو كلمة السر غير صحيحة.",
    NetworkError: "عذرا ، كان هناك خطأ في الاتصال ، يرجى المحاولة مرة أخرى.",
    "account.pending": "الحساب في انتظار الموافقة",
    "account.pending.instructions":
      "تم إنشاء حسابك بنجاح ، ستتمكن من تسجيل الدخول بعد موافقة مسؤول الموقع الخاص بك.",
    "try.again": "حاول مجددا",
    "user.list": "User List",
    "pending.users.list": "المستخدمون غير النشطين",
    "active.users.list": "المحررون",
    "admin.users.list": "المديرون",
    "see.more": "شاهد المزيد",
    "user.column.userStatus": "حالة المستخدم",
    "user.column.email": "البريد الإلكتروني",
    "user.column.fullName": "الاسم",
    "make.regular": "التغيير إلى محرر",
    "make.admin": "التغيير إلى مدير",
    "disable.user": "تعطيل المستخدم",
    "enable.user": "تنشيط المستخدم",
    UNCONFIRMED: "غير مؤكد",
    CONFIRMED: "مؤكد",
    FORCE_CHANGE_PASSWORD: "غير مؤكد",
    "manage.users": "ادارة المستخدمين",
    "pending.users.list.desc":
      "هذه قائمة بالمستخدمين ، الذين تم تسجيلهم مؤخرًا ، ولكن لا يزال يتعين تأكيدهم من قبل المدير ، والمستخدمين الذين تم إلغاء تنشيطهم من قبل المديرين.",
    "active.users.list.desc":
      "يمكن للمحررين تحرير كل المحتوى في التطبيق ، لكن لن يتمكنوا من إجراء تغييرات على المستخدمين الآخرين.",
    "admin.users.list.desc":
      "يمكن للمديرين فعل أي شيء يمكن للمحررين القيام به ، ويمكنهم إدارة المستخدمين الآخرين.",
    "user.list.empty":
      "ليس لديك حاليًا أي مستخدمين آخرين ، بمجرد تسجيل المستخدمين ، سيتم إدراجهم هنا.",
  },
  [Lang.en]: {
    home: "Home",
    "welcome.back": "Welome back! \n {{name}}",
    "home.title": "Welcome to United Logistics Ordering System",
    "home.access.dashboard": "Access the dashboard",
    "home.access.dashboard.admin":
      "The dashboard allows you to manage orders, locations and other categories",
    "home.access.dashboard.dispatcher":
      "The dashboard allows you to manage orders, locations and other categories",
    "home.access.dashboard.driver":
      "The dashboard allows you to view and respond to customer orders",
    "home.access.dashboard.store-owner":
      "The dashboard gives you an overview of your transactions",
    "home.access.dashboard.distributor":
      "The dashboard gives you an overview of your transactions",
    "home.access.sign.in.text":
      "To access the dashboard, you need to sign in first.",
    "home.access.sign.in": "Sign In",
    "layout.company": "United Logistics Of Illinois",
    "layout.copyright": "© {{date}}",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "Profile",
    logout: "Sign Out",
    "errors.general": "Sorry, there was an error!",
    "errors.required.fields": "Please complete required fields!",
    save: "Save",
    "file.format": "File format",
    back: "Back",
    download: "Download",
    delete: "Delete",
    edit: "Edit",
    restore: "Restore",
    "edit.document.remove.existing.file": "Remove existing file",
    "document.deleted.message":
      "This document has been deleted, click 'Restore' to get it back",
    "delete.modal.title": "Please confirm",
    "delete.modal.body": "Are you sure you want to delete this document?",
    cancel: "Cancel",
    "deleted.documents": "Deleted documents",
    "welcome.user": "Welcome {{displayName}}!",
    ok: "Ok",
    "invalid.date": "Invalid Date Format",
    search: "Search",
    "no.result": "No results found, please try different keywords.",
    "deleted.documents.no.documents": "No deleted documents.",
    "drawer.advanced.search": "Advanced search",
    "search.searchAll": "Enter a keyword",
    "advanced.search.title": "Advanced search",
    "show.more": "Show more",
    "client.info.zg": "",
    "client.info.presidency": "The presidency",
    "client.info.upr": "UPR",
    attachments: "Attachments",
    "no.attachments": "no attachments",
    "add.attachments": "Add attachments",
    "add.attachments.title": "Add attachments",
    "back.to.document": "Back to document",
    "click.to.download": "Click to download",
    "client.info.dev": "Experimental App",
    signin: "Sign in",
    "email.address": "Email Address",
    password: "Password",
    "create.account.link": "Don't have an account? Sign Up",
    "forgot.password": "Forgot password?",
    "already.have.an.account": "Already have an account? Sign in",
    "sign.up": "Sign up",
    "confirm.password": "Confirm Password",
    "last.name": "Last Name",
    "first.name": "First Name",
    "confirm.sign.up": "Confirm Sign Up",
    "sign.up.code": "Code",
    InvalidPasswordException: "Password does not conform to policy.",
    CodeMismatchException:
      "Invalid verification code provided, please try again.",
    "request.new.password.code": "Send me verification code",
    "check.email.for.code":
      "Please check your email for password reset code and enter it below.",
    "reset.password": "Reset your password",
    "reset.password.instructions":
      "Please provide your email address, we will send you a verification link to reset your password.",
    "reset.password.send.link.button": "Send Me Password Reset Instructions",
    "reset.password.email.sent.success.instructions":
      "Great! an email with instructions on how to reset your password was sent to the Email address you provided.",
    "confirm.sign.up.instructions":
      "Please check your email for verification code and enter it below, to complete your sign up.",
    "all.fields.required": "All fields are required.",
    "password.mismatch": "Please double check the passwords you entered.",
    "password.instructions":
      "Password policy: minimum of 8 characters, at least one number, one uppercase letter, one lowercase letter, and one special character.",
    "email.required": "Email address is required.",
    UsernameExistsException:
      "An account with the given email already exists, please sign in.",
    UserNotFoundException: "User does not exist.",
    NotAuthorizedException: "Incorrect username or password.",
    NetworkError: "Sorry, there was a connection error, please try again.",
    "account.pending": "Account pending approval",
    "account.pending.instructions":
      "Your account was created successfully, you will be able to sign in upon approval by your site administrator.",
    "try.again": "Try again",
    "user.list": "User List",
    "pending.users.list": "Inactive Users",
    "active.users.list": "Editors",
    "admin.users.list": "Administrators",
    "see.more": "See More",
    "user.column.userStatus": "User Status",
    "user.column.email": "Email Address",
    "user.column.fullName": "Name",
    "make.regular": "Switch to editor",
    "make.admin": "Switch to admin",
    "disable.user": "Disable user",
    "enable.user": "Enable user",
    UNCONFIRMED: "Unconfirmed",
    CONFIRMED: "Confirmed",
    FORCE_CHANGE_PASSWORD: "Unconfirmed",
    "manage.users": "Manage users",
    "pending.users.list.desc":
      "This is a list of users, that recently signed up, but still need to be confirmed by an admin, and the ones that were deactivated by admins.",
    "active.users.list.desc":
      "Editors can edit all content in the app, but will not be able to make changes to other users.",
    "admin.users.list.desc":
      "Administrators can do anything editors can do, and they can manage other users.",
    "user.list.empty":
      "You currently have no other users, once users sign up, they will be listed here.",
    "error.not.found":
      "Sorry, page not found, return to the <0> home page</0>.",
    "auth/invalid-action-code":
      "Please try again, the verification link is no longer valid.",
    "auth/invalid-email": "Invalid Email.",
    "auth/user-not-found": "User Not Found.",
    "auth/weak-password": "Password is weak.",
    "auth/wrong-password": "Error! double check your password.",
    "auth/network-request-failed": "Network request failed.",
    "auth/email-already-in-use":
      "An account with this Email address already exists, please login.",
    "verify.email": " Welcome Onboard - Just One Quick Step Left!",
    "verify.email.success":
      "Thanks for verifying your Email address, click the link below to access your account.",
    "verify.email.resend.success":
      "Please check your Email account for a new link to verify your Email address, if you can't find it, check your spam folder.",
    "verify.email.instructions":
      "Welcome back!  To ensure a secure and personalized experience, please click the button below to verify your email address.",
    "verify.email.button": "Verify your Email!",
    "verify.email.resend": "Send me a new link!",
    "create.new.password": "Create a new password",
    "create.new.password.error":
      "Oh no! The link is invalid, please try reseting the password again!",
    "create.new.password.instructions":
      "Enter a new password below for the account identified by {{email}}.",
    "create.new.password.success":
      "Nice! your password was successfully updated.",
    "verify.email.unverified":
      "Welcome to the team!  To secure your account and unlock all our awesome features, kindly verify your email address. We've sent you a quick email with instructions - check your inbox now.",
    "access.dashboard": "Access your dashboard",
    "coming.soon": "Coming Soon",
  },
  [Lang.fr]: {
    home: "Accueil",
    "layout.company": "United Logistics Of Illinois",
    "layout.copyright": "© {{date}}",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "Profil",
    logout: "Déconnexion",
    "errors.general": "Désolé, il y a eu une erreur!",
    "errors.required.fields": "Veuillez compléter les informations requises!",
    save: "Sauvegarder",
    "file.format": "Format du fichier",
    back: "Retour",
    download: "Télécharger",
    delete: "Supprimer",
    edit: "Éditer",
    restore: "Restaurer",
    "edit.document.remove.existing.file": "Supprimer le fichier actuel",
    "document.deleted.message":
      "Ce document a été supprimé, cliquez sur «Restaurer» pour le récupérer",
    "delete.modal.title": "Veuillez confirmer",
    "delete.modal.body": "Voulez-vous vraiment supprimer ce document?",
    cancel: "Annuler",
    "deleted.documents": "Documents supprimés",
    "welcome.user": "Bienvenue {{displayName}}!",
    ok: "Ok",
    "invalid.date": "Format de date non valide",
    search: "Rechercher",
    "no.result":
      "Aucun résultat trouvé, veuillez essayer différents mots clés.",
    "deleted.documents.no.documents": "Aucun document.",
    "drawer.advanced.search": "Recherche Avancée",
    "search.searchAll": "Entrez un mot-clé",
    "advanced.search.title": "Recherche Avancée",
    "show.more": "Afficher plus",
    "client.info.zg": "",
    "client.info.presidency": "La présidence",
    "client.info.upr": "UPR",
    attachments: "Pièces jointes",
    "no.attachments": "pas de pièces jointes",
    "add.attachments": "Ajouter des pièces jointes",
    "add.attachments.title": "Ajouter des pièces jointes",
    "back.to.document": "Retour au document",
    "view.attahcment.title": "Pièce jointe: {{title}}",
    "alerts.attachments.saved": "Pièces jointes enregistrées avec succès",
    "click.to.download": "Cliquez pour télécharger",
    "client.info.dev": "Application expérimentale",
    "client.info.panpa": "Le Port Autonome de Nouakchott",
    signin: "Se connecter",
    "email.address": "Adresse e-mail",
    password: "Mot de passe",
    "create.account.link": "Vous n'avez pas de compte ? S'inscrire",
    "forgot.passowrd": "Mot de passe oublié?",
    "already.have.an.account": "Vous avez déjà un compte? Se connecter",
    "sign.up": "S'inscrire",
    "confirm.password": "Confirmez le mot de passe",
    "last.name": "Nom de famille",
    "first.name": "Prénom",
    "confirm.sign.up": "Confirmer l'inscription",
    "sign.up.code": "Code",
    InvalidPasswordException: "Le mot de passe ñ'est pas conforme au format.",
    CodeMismatchException:
      "Code de vérification fourni non valide, veuillez réessayer.",
    "request.new.password.code": "Envoyez-moi le code de vérification",
    "check.email.for.code":
      "Veuillez consulter votre e-mail pour le code de réinitialisation du mot de passe et entrez-le ci-dessous.",
    "create.new.password": "Créer un nouveau mot de passe",
    "password.rest.instructions":
      "Veuillez fournir votre adresse e-mail, nous vous enverrons un code de vérification pour réinitialiser votre mot de passe.",
    "confirm.sign.up.instructions":
      "Veuillez consulter votre e-mail pour le code de vérification et entrez-le ci-dessous pour terminer votre inscription.",
    "all.fields.required": "Tous les champs sont requis.",
    "password.mismatch":
      "Veuillez vérifier les mots de passe que vous avez entrés.",
    "password.instructions":
      "Exigences de mot de passe : minimum de 8 caractères, au moins un chiffre, une lettre majuscule et une lettre minuscule.",
    "email.required": "Adresse e-mail est nécessaire.",
    UsernameExistsException:
      "Un compte associé à l'e-mail indiqué existe déjà, veuillez vous connecter.",
    UserNotFoundException: "L'utilisateur n'existe pas.",
    NotAuthorizedException: "Identifiant ou mot de passe incorrect.",
    NetworkError:
      "Désolé, il y a eu une erreur de connexion, veuillez réessayer.",
    "account.pending": "Compte en attente d'approbation",
    "account.pending.instructions":
      "Votre compte a été créé avec succès, vous pourrez vous connecter après approbation par l'administrateur de votre site.",
    "try.again": "Réessayer",
    "user.list": "liste d'utilisateur",
    "pending.users.list": "Utilisateurs inactifs",
    "active.users.list": "Éditeurs/Éditrices",
    "admin.users.list": "Administrateurs/Administratrices",
    "see.more": "Voir plus",
    "user.column.userStatus": "Statut de l'utilisateur",
    "user.column.email": "Adresse e-mail",
    "user.column.fullName": "Nom",
    "make.regular": "Passer à l'éditeur",
    "make.admin": "Passer en administrateur",
    "disable.user": "Désactiver l'utilisateur",
    "enable.user": "Activer l'utilisateur",
    UNCONFIRMED: "Non confirmé",
    CONFIRMED: "Confirmé",
    FORCE_CHANGE_PASSWORD: "Non confirmé",
    "manage.users": "Gérer les utilisateurs",
    "pending.users.list.desc":
      "Il s'agit d'une liste d'utilisateurs qui se sont récemment inscrits, mais qui doivent encore être confirmés par un administrateur, et de ceux qui ont été désactivés par les administrateurs.",
    "active.users.list.desc":
      "Les éditeurs peuvent modifier tout le contenu du site Web, mais ne pourront pas apporter de modifications aux autres utilisateurs.",
    "admin.users.list.desc":
      "Les administrateurs peuvent faire tout ce que les éditeurs peuvent faire et ils peuvent gérer d'autres utilisateurs.",
    "user.list.empty":
      "Vous n'avez actuellement aucun autre utilisateur, une fois les utilisateurs inscrits, ils seront répertoriés ici.",
  },
};
