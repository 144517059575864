import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
// hooks
import useAuth from "hooks/useAuth";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import AuthLayout from "layouts/auth";
// guards
import GuestGuard from "guards/GuestGuard";
import AuthGuard from "guards/AuthGuard";
import RoleBasedGuard from "guards/RoleBasedGuard";

// config
import { PATH_DASHBOARD } from "routes/paths";

// components
import LoadingScreen from "components/LoadingScreen";
// Types
import { UserRole } from "types/data";

// Root
import App from "App";

// Loaders
import { loader as locationListLoader } from "pages/locations/loaders/list";
import { loader as groupListLoader } from "pages/groups/loaders/list";
import { verifyLoader } from "pages/auth/loaders/verifyLoader";
// ----------------------------------------------------------------------

const queryClient = new QueryClient();

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && user !== null;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

// AUTHENTICATION
const SignIn = Loadable(lazy(() => import("pages/auth/sign-in/SignIn")));
const SignUp = Loadable(lazy(() => import("pages/auth/sign-up/SignUp")));
const ForgotPassword = Loadable(lazy(() => import("pages/auth/ResetPassword")));
const ConfirmSignUp = Loadable(lazy(() => import("pages/auth/Verify")));
const Pending = Loadable(lazy(() => import("pages/auth/Pending")));

const UnverifiedEmail = Loadable(
  lazy(() => import("pages/auth/UnverifiedEmail"))
);

// DASHBOARD

// GENERAL
const ComingSoon = Loadable(lazy(() => import("pages/ComingSoon")));

const LoggedInHome = Loadable(lazy(() => import("pages/LoggedInHome")));

// MAIN
const HomePage = Loadable(lazy(() => import("pages/Home")));

// Invoices
const InvoiceList = Loadable(lazy(() => import("pages/invoices/ListWrapper")));
const InvoiceView = Loadable(lazy(() => import("pages/invoices/View")));
const InvoiceEdit = Loadable(lazy(() => import("pages/invoices/Edit")));

// Orders
const OrderList = Loadable(lazy(() => import("pages/orders/ListWrapper")));
const OrderView = Loadable(lazy(() => import("pages/orders/View")));
const OrderEdit = Loadable(lazy(() => import("pages/orders/Edit")));
const OrderNew = Loadable(lazy(() => import("pages/orders/Create")));
const OrderAddDriver = Loadable(lazy(() => import("pages/orders/AddDriver")));

// Transaction
const TransactionList = Loadable(
  lazy(() => import("pages/transactions/ListWrapper"))
);
const TransactionView = Loadable(lazy(() => import("pages/transactions/View")));
const TransactionEdit = Loadable(lazy(() => import("pages/transactions/Edit")));
const TransactionNew = Loadable(
  lazy(() => import("pages/transactions/Create"))
);

// Locations
const LocationList = Loadable(
  lazy(() => import("pages/locations/ListWrapper"))
);
const LocationView = Loadable(lazy(() => import("pages/locations/View")));
const LocationEdit = Loadable(lazy(() => import("pages/locations/Edit")));
const LocationNew = Loadable(lazy(() => import("pages/locations/Create")));

// Users
const UsersList = Loadable(lazy(() => import("pages/users/List")));
const UsersView = Loadable(lazy(() => import("pages/users/View")));
const UsersEdit = Loadable(lazy(() => import("pages/users/Edit")));
// My Profile
const ViewMyProfile = Loadable(lazy(() => import("pages/profile/View")));
const EditMyPreferences = Loadable(lazy(() => import("pages/profile/Edit")));

// Drivers
const DriverList = Loadable(lazy(() => import("pages/drivers/List")));
const DriverView = Loadable(lazy(() => import("pages/drivers/View")));
const DriverEdit = Loadable(lazy(() => import("pages/drivers/Edit")));
const DriverNew = Loadable(lazy(() => import("pages/drivers/Create")));
// Groups
const GroupList = Loadable(lazy(() => import("pages/groups/ListWrapper")));
const GroupView = Loadable(lazy(() => import("pages/groups/View")));
const GroupEdit = Loadable(lazy(() => import("pages/groups/Edit")));
const GroupNew = Loadable(lazy(() => import("pages/groups/Create")));

// Products
const ProductList = Loadable(lazy(() => import("pages/products/List")));
const ProductEdit = Loadable(lazy(() => import("pages/products/Edit")));
const ProductNew = Loadable(lazy(() => import("pages/products/Create")));

// Sources
const SourceList = Loadable(lazy(() => import("pages/sources/List")));
const SourceView = Loadable(lazy(() => import("pages/sources/View")));
const SourceEdit = Loadable(lazy(() => import("pages/sources/Edit")));
const SourceNew = Loadable(lazy(() => import("pages/sources/Create")));

// Distributors
const DistributorList = Loadable(lazy(() => import("pages/distributors/List")));
const DistributorView = Loadable(lazy(() => import("pages/distributors/View")));
const DistributorEdit = Loadable(lazy(() => import("pages/distributors/Edit")));
const DistributorNew = Loadable(
  lazy(() => import("pages/distributors/Create"))
);

// Fleet
const FleetList = Loadable(lazy(() => import("pages/fleet/List")));
const FleetView = Loadable(lazy(() => import("pages/fleet/View")));
const FleetEdit = Loadable(lazy(() => import("pages/fleet/Edit")));
const FleetNew = Loadable(lazy(() => import("pages/fleet/Create")));

const Page500 = Loadable(lazy(() => import("pages/Page500")));
const Page403 = Loadable(lazy(() => import("pages/Page403")));
const Page404 = Loadable(lazy(() => import("pages/Page404")));

export const routes = [
  {
    element: <App />,
    errorElement: <Page500 />,
    children: [
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "sign-in",
            element: (
              <GuestGuard>
                <SignIn />
              </GuestGuard>
            ),
          },
          {
            path: "sign-up",
            element: (
              <GuestGuard>
                <SignUp />
              </GuestGuard>
            ),
          },
          { path: "reset-password", element: <ForgotPassword /> },
          { path: "verify", element: <ConfirmSignUp />, loader: verifyLoader },
          { path: "unverified-email", element: <UnverifiedEmail /> },
          { path: "pending", element: <Pending /> },
        ],
      },

      // Dashboard Routes
      {
        path: "dashboard",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          // general
          { element: <LoggedInHome />, index: true },
          { path: "welcome", element: <LoggedInHome /> },
          {
            path: "analytics",
            element: <ComingSoon />,
          },
          // account
          {
            path: "profile",

            children: [
              { element: <ViewMyProfile />, index: true },
              { path: "edit", element: <EditMyPreferences /> },
            ],
          },
          {
            path: "invoices",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.invoices.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <InvoiceList />,
              },
              {
                path: ":id/view",
                element: <InvoiceView />,
              },
              {
                path: ":id/edit",
                element: <InvoiceEdit />,
              },
            ],
          },

          //managment
          {
            path: "users",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.users.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <UsersList />,
              },
              {
                path: ":id/edit",
                element: <UsersEdit />,
              },
              {
                path: ":id/view",
                element: <UsersView />,
              },
            ],
          },
          {
            path: "groups",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.groups.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: (
                  <RoleBasedGuard
                    requiredRoles={[UserRole.Admin, UserRole.Dispatcher]}
                    hasContent
                  >
                    <GroupList />
                  </RoleBasedGuard>
                ),
                loader: groupListLoader(queryClient),
              },
              {
                path: "new",
                element: <GroupNew />,
              },
              {
                path: ":id/edit",
                element: <GroupEdit />,
              },
              {
                path: ":id/view",
                element: <GroupView />,
              },
            ],
          },
          {
            path: "orders",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.orders.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <OrderList />,
              },
              {
                path: "new",
                element: <OrderNew />,
              },
              {
                path: ":id/edit",
                element: <OrderEdit />,
              },
              {
                path: ":id/view",
                element: <OrderView />,
              },
              {
                path: ":id/driver",
                element: <OrderAddDriver />,
              },
            ],
          },
          {
            path: "transactions",
            children: [
              {
                element: (
                  <Navigate to={PATH_DASHBOARD.transactions.list} replace />
                ),
                index: true,
              },
              {
                path: "list",
                element: <TransactionList />,
              },
              {
                path: "new",
                element: <TransactionNew />,
              },
              {
                path: ":id/edit",
                element: <TransactionEdit />,
              },
              {
                path: ":id/view",
                element: <TransactionView />,
              },
            ],
          },
          {
            path: "locations",
            children: [
              {
                element: (
                  <Navigate to={PATH_DASHBOARD.locations.list} replace />
                ),
                index: true,
              },
              {
                path: "list",
                element: <LocationList />,
                loader: locationListLoader(queryClient),
              },
              {
                path: "new",
                element: <LocationNew />,
              },
              {
                path: ":id/edit",
                element: <LocationEdit />,
              },
              {
                path: ":id/view",
                element: <LocationView />,
              },
            ],
          },
          {
            path: "distributors",
            children: [
              {
                element: (
                  <Navigate to={PATH_DASHBOARD.distributors.list} replace />
                ),
                index: true,
              },
              {
                path: "list",
                element: (
                  <RoleBasedGuard
                    requiredRoles={[UserRole.Admin, UserRole.Dispatcher]}
                    hasContent
                  >
                    <DistributorList />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "new",
                element: <DistributorNew />,
              },
              {
                path: ":id/edit",
                element: <DistributorEdit />,
              },
              {
                path: ":id/view",
                element: <DistributorView />,
              },
            ],
          },
          {
            path: "sources",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.sources.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <SourceList />,
              },
              {
                path: "new",
                element: <SourceNew />,
              },

              {
                path: ":id/edit",
                element: <SourceEdit />,
              },
              {
                path: ":id/view",
                element: <SourceView />,
              },
            ],
          },
          {
            path: "products",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.products.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <ProductList />,
              },
              {
                path: "new",
                element: <ProductNew />,
              },
              {
                path: ":id/edit",
                element: <ProductEdit />,
              },
            ],
          },
          {
            path: "drivers",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.drivers.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <DriverList />,
              },
              {
                path: "new",
                element: <DriverNew />,
              },
              {
                path: ":id/edit",
                element: <DriverEdit />,
              },
              {
                path: ":id/view",
                element: <DriverView />,
              },
            ],
          },
          {
            path: "fleet",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.fleet.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: (
                  <RoleBasedGuard requiredRoles={[UserRole.Admin]} hasContent>
                    <FleetList />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "new",
                element: (
                  <RoleBasedGuard requiredRoles={[UserRole.Admin]} hasContent>
                    <FleetNew />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ":id/edit",
                element: (
                  <RoleBasedGuard requiredRoles={[UserRole.Admin]} hasContent>
                    <FleetEdit />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ":id/view",
                element: (
                  <RoleBasedGuard requiredRoles={[UserRole.Admin]} hasContent>
                    <FleetView />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },

      // Main Routes
      {
        path: "*",
        element: <LogoOnlyLayout />,
        children: [
          { path: "500", element: <Page500 /> },
          { path: "404", element: <Page404 /> },
          { path: "403", element: <Page403 /> },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [{ element: <HomePage />, index: true }],
      },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];
export default function Router() {
  return useRoutes(routes);
}
