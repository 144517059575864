import {
  format,
  getTime,
  formatDistanceToNow,
  addHours,
  getDate,
  parseISO,
} from "date-fns";
import { Timestamp } from "firebase/firestore";

// ----------------------------------------------------------------------

export function isDateValid(date?: Date): date is Date {
  return !!date && date instanceof Date && !isNaN(date.getTime());
}
export function fDate(date: Date | string | number) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateShort(date: Date | string | number) {
  return format(new Date(date), "MM/dd/yyyy");
}
export function fDateTime(date: Date | string | number) {
  return format(new Date(date), "MM/dd/yyyy p");
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getDateFromFirestore(fsDate?: Timestamp | string | null) {
  if (fsDate && typeof fsDate !== "string") {
    return fsDate.toDate();
  }
  if (fsDate && typeof fsDate === "string") {
    return parseISO(fsDate);
  }
  return null;
}

export function fDateRange(date: Date | string | number, count: number = 6) {
  const end = addHours(new Date(date), count);
  const startDateOfMonth = getDate(new Date(date));
  const endDateOfMonth = getDate(end);
  if (startDateOfMonth === endDateOfMonth) {
    return (
      format(new Date(date), "hh:mm aaa") +
      " - " +
      format(end, "hh:mm aaa") +
      " on " +
      format(new Date(date), "MM/dd/yyyy")
    );
  }

  return (
    format(new Date(date), "MM/dd/yyyy hh:mm aaa") +
    " - " +
    format(end, "MM/dd/yyyy hh:mm aaa")
  );
}

export function getDateFieldValue(date?: Date): Date | null {
  if (!date) {
    return null;
  }
  return date;
}
