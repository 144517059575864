import { Timestamp, WhereFilterOp } from "firebase/firestore";
export enum UserRole {
  Admin = "admin",
  StoreOwner = "store-owner",
  Driver = "driver",
  Dispatcher = "dispatcher",
  Distributor = "distributor",
  Unknown = "unknown",
}

export enum UserStatus {
  Active = "active",
  Pending = "pending",
  Banned = "banned",
}

export enum FuelType {
  Regular = "regular",
  Midgrade = "midgrade",
  Premium = "premium",
  BioDiesel = "bio-diesel",
  Diesel = "diesel",
  Kerosene = "kerosene",
  e85 = "E-85",
}

export enum OrderStatus {
  Pending = "pending",
  Accepted = "accepted",
  Dispatched = "dispatched",
  Delivered = "delivered",
  Complete = "complete",
}

export type OrderStatusAll = OrderStatus | "all";

export enum InvoiceStatus {
  Published = "published",
  Draft = "draft",
}

export enum VehicleType {
  Truck = "truck",
  Tanker = "tanker",
  Van = "van",
  SUV = "suv",
  Sedan = "sedan",
  Other = "other",
}

type NotificationPreference = {
  mobile: boolean;
  email: boolean;
};
export type UserPreferences = {
  newOrder: NotificationPreference;
  orderStatusUpdate: NotificationPreference;
  newUser?: NotificationPreference;
};

export type InitialUser = {
  id?: string;
  emailAddress: string;
  displayName: string;
  phoneNumber?: string;
  role: UserRole;
  status?: UserStatus;
  group?: string;
  groupName?: string;
  distributor?: string;
  distributorName?: string;
  subJobber?: boolean;
  driver?: string;
  driverName?: string;
  isVerified?: boolean;
  created: Timestamp;
  lastUpdated: Timestamp;
  keywords?: string[];
  photoUrl?: string | File;
  preferences?: UserPreferences;
};

export type User = InitialUser & {
  id: string;
};

export type InitialDriver = {
  id?: string;
  name: string;
  emailAddress?: string;
  dob?: Timestamp | null;
  active: boolean;
  cdlNumber?: string;
  cdlExpire?: Timestamp | null;
  medicalCardExpire?: Timestamp | null;
  phoneNumber?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  files?: (string | File)[];
  keywords?: string[];
  note?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
};

export type Driver = InitialDriver & {
  id: string;
};

export type InitialGroup = {
  id?: string;
  name: string;
  owners?: string[];
  active: boolean;
  contactName?: string;
  phoneNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  note?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
  keywords?: string[];
  photoUrl?: string | File;
};

export type Group = InitialGroup & {
  id: string;
};

export type InitialLocation = {
  id?: string;
  name: string;
  active: boolean;
  directBilling?: boolean;
  keepFull: boolean;
  doNotDeliver?: boolean;
  contactName?: string;
  defaultCost?: number;
  phoneNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  group?: string;
  groupName?: string; // TODO - populate group name
  distributor?: string;
  distributorName?: string; // TODO - populate distributor name
  subJobber?: string;
  subJobberName?: string; // TODO - populate sub-jobber name
  note?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
  photoUrl?: string | File;
};

export type Location = InitialLocation & {
  id: string;
};

export type InitialDistributor = {
  dist: any;
  id?: string;
  name: string;
  active: boolean;
  contactName?: string;
  phoneNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
  keywords?: string[];
  note?: string;
  photoUrl?: string | File;
  subJobber?: boolean;
};
export type Distributor = InitialDistributor & {
  id: string;
};

export type InitialOrder = {
  id?: string;
  bol?: string;
  bolArray?: string[];
  status: OrderStatus;
  location: string;
  locationName: string;
  group?: string;
  groupName?: string;
  distributor?: string;
  distributorName?: string;
  subJobber?: string;
  subJobberName?: string;
  defaultCost?: number;
  truck?: string;
  truckName?: string;
  tanker?: string;
  tankerName?: string;
  fuelSource?: string;
  fuelProduct?: string;
  deliveryDate?: Timestamp | null;
  requestedDate?: Timestamp | null; // 6 hour window, 24 hours or more in the future.
  files?: (string | File)[];
  note?: string;
  keywords?: string[];
  created: Timestamp;
  lastUpdated: Timestamp;
  createdBy?: string;
  createdByName?: string;
  createdByRole?: string;
  fuelRegular?: number;
  fuelMidgrade?: number;
  fuelPremium?: number;
  fuelBioDiesel?: number;
  fuelDiesel?: number;
  fuelKerosene?: number;
  fuelE85?: number;
  totalFuel?: number;
  driver?: string;
  driverName?: string;
  transactionId?: string;
  rejectedBy?: string;
  rejectedByRole?: string;
  rejectedByName?: string;
  dispatchConfirmed?: boolean;
};

export type Order = InitialOrder & {
  id: string;
};

export type InitialTransaction = {
  id?: string;
  bol?: string;
  bolArray?: string[];
  cost?: number;
  demurrage?: number;
  location: string;
  locationName: string;
  group: string;
  groupName: string;
  distributor: string;
  distributorName: string;
  subJobber?: string;
  subJobberName?: string;
  truck?: string;
  truckName?: string;
  tanker?: string;
  tankerName?: string;
  fuelSource?: string;
  fuelProduct?: string;
  driver?: string;
  driverName?: string;
  deliveryDate?: Timestamp | null;
  files?: (string | File)[];
  note?: string;
  keywords?: string[];
  created: Timestamp;
  lastUpdated: Timestamp;
  createdBy?: string;
  createdByName?: string;
  directBilling?: boolean; // from location
  fuelRegular?: number;
  fuelMidgrade?: number;
  fuelPremium?: number;
  fuelE85?: number;
  fuelBioDiesel?: number;
  fuelDiesel?: number;
  fuelKerosene?: number;
  totalFuel?: number;
  orderId?: string;
};

export type Transaction = InitialTransaction & {
  id: string;
};

export type InitialInvoice = {
  id?: string;
  invoiceId?: string;
  billToId?: string;
  billToType?: string | "group" | "distributor" | "location";
  billToName?: string;
  shipToId?: string;
  shipToType?: string | "group" | "distributor" | "location";
  shipToName?: string;
  discount?: number;
  transactions?: string[];
  status?: InvoiceStatus;
  paid?: boolean;
  created?: Timestamp;
  lastUpdated?: Timestamp;
  xslxFile?: string;
  pdfFile?: string;
  transactionData?: Record<string, string | number>[];
  createdBy?: string;
  createdByName?: string;
  dueDate?: Timestamp | null;
  fromDate?: Timestamp | null;
  toDate?: Timestamp | null;
  note?: string;
  searchState?: Record<string, any>;
  totalFuel?: number;
  totalCost?: number;
  keywords?: string[];
  lastUpdateFromCloud?: boolean;
};

export type Invoice = InitialInvoice & {
  id: string;
  created: Timestamp;
  lastUpdated: Timestamp;
};

export type FuelSource = {
  id: string;
  active: boolean;
  contactName?: string;
  phoneNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
  keywords?: string[];
  note?: string;
};

export type FuelProduct = {
  id: string;
  active: boolean;
  note?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
};

export type InitialVehicle = {
  id?: string;
  name: string;
  type: VehicleType;
  active: boolean;
  make?: string;
  model?: string;
  vin?: string;
  year?: string;
  files?: (string | File)[];
  note?: string;
  created: Timestamp;
  lastUpdated: Timestamp;
};

export type Vehicle = InitialVehicle & {
  id: string;
};

export type AlgoliaResultObject = {
  name: string;
  objectID: string;
  path: string;
} & Location;

export type AutoCompleteResultObject = { name?: string } & (
  | Order
  | Transaction
  | Group
  | Distributor
  | Location
  | Driver
  | User
  | FuelProduct
  | FuelSource
);

// Filters

export type SortFilters = {
  order?: string;
  orderBy?: string;
};

export type AutoCompleteFilters = {
  keyword?: string;
  groupId?: string;
  rawQuery?: [string, WhereFilterOp, any][];
};
export type DistributorFilters =
  | (SortFilters & {
      keyword?: string;
      active?: boolean;
    })
  | undefined;

export type GroupFilters =
  | (SortFilters & {
      keyword?: string;
      status?: string;
    })
  | undefined;

export type FleetFilters =
  | (SortFilters & {
      name?: string;
      active?: boolean;
    })
  | undefined;

export type TruckFilters =
  | (SortFilters & {
      name?: string;
      active?: boolean;
    })
  | undefined;

export type TankerFilters =
  | (SortFilters & {
      name?: string;
      active?: boolean;
    })
  | undefined;

export type LocationFilters =
  | (SortFilters & {
      keyword?: string;
      status?: string;
      group?: string;
      distributor?: string;
      subJobber?: boolean;
      keepFull?: boolean;
      directBilling?: boolean;
      doNotDeliver?: boolean;
    })
  | undefined;

export type DriverFilters =
  | (SortFilters & {
      keyword?: string;
      active?: boolean;
    })
  | undefined;

export type FuelSourceFilters =
  | (SortFilters & {
      active?: boolean;
    })
  | undefined;

export type FuelProductFilters =
  | (SortFilters & {
      active?: boolean;
    })
  | undefined;

export type OrderFilters =
  | (SortFilters & {} & Omit<OrderFiltersState, "page" | "perPage">)
  | undefined;

export type AggregateFilters = {
  createdFrom?: Date;
  createdTo?: Date;
  distributor?: string;
  group?: string;
  location?: string;
  driver?: string;
};

export type TransactionFilters =
  | (SortFilters & {} & Omit<TransactionFiltersState, "page" | "perPage">)
  | undefined;

export type UserFilters =
  | (SortFilters & {
      keyword?: string;
      status?: UserStatus;
      isVerified?: boolean;
      role?: UserRole;
    } & Omit<UserFiltersState, "page" | "perPage">)
  | undefined;

export type OrderFiltersState = {
  page: number;
  perPage: number;
  keyword?: string;
  status?: OrderStatus;
  location?: string;
  group?: string;
  distributor?: string;
  subJobber?: boolean;
  driver?: string;
  rejectedBy?: string;
  dispatchConfirmed?: boolean;
  requestedDateFrom?: Date;
  requestedDateTo?: Date;
  rawQuery?: [string, WhereFilterOp, any][];
};

export type TransactionFiltersState = {
  page: number;
  perPage: number;
  bol?: string;
  keyword?: string;
  location?: string;
  group?: string;
  distributor?: string;
  driver?: string;
  fuelProduct?: string;
  fuelSource?: string;
  tanker?: string;
  truck?: string;
  deliveryDateFrom?: Date;
  deliveryDateTo?: Date;
  directBilling?: boolean;
};

export enum TransactionAction {
  UpdatePage = "transaction-update-page",
  UpdatePerPage = "transaction-update-per-page",
  UpdateBOL = "transaction-update-bol",
  UpdateKeyword = "transaction-update-keyword",
  UpdateLocation = "transaction-update-location",
  UpdateGroup = "transaction-update-group",
  UpdateDistributor = "transaction-update-distributor",
  UpdateDriver = "transaction-update-driver",
  UpdateFuelSource = "transaction-update-fuel-source",
  UpdateFuelProduct = "transaction-update-fuel-product",
  UpdateTruck = "transaction-update-truck",
  UpdateTanker = "transaction-update-tanker",
  UpdateDeliveryDateFrom = "transaction-update-delivery-date-from",
  UpdateDeliveryDateTo = "transaction-update-delivery-date-to",
  UpdateDirectBilling = "transaction-update-direct-billing",
}

export enum OrderAction {
  UpdatePage = "order-update-page",
  UpdatePerPage = "order-update-per-page",
  UpdateKeyword = "order-update-keyword",
  UpdateStatus = "order-update-status",
  UpdateLocation = "order-update-location",
  UpdateGroup = "order-update-group",
  UpdateDistributor = "order-update-distributor",
  UpdateRequestedDateFrom = "order-update-requested-date-from",
  UpdateRequestedDateTo = "order-update-requested-date-to",
}

export type GroupFiltersState = {
  page: number;
  perPage: number;
  status?: string;
  keyword?: string;
};

export type LocationFiltersState = {
  page: number;
  perPage: number;
  status?: string;
  keyword?: string;
  group?: string;
  distributor?: string;
  subJobber?: boolean;
  keepFull?: boolean;
  directBilling?: boolean;
  doNotDeliver?: boolean;
};

export enum LocationAction {
  UpdatePage = "location-update-page",
  UpdatePerPage = "location-update-per-page",
  UpdateStatus = "location-update-status",
  UpdateKeyword = "location-update-keyword",
  UpdateGroup = "location-update-group",
  UpdateDistributor = "location-update-distributor",
  UpdateSubJobber = "location-update-sub-jobber",
  UpdateKeepFull = "location-update-keep-full",
  UpdateDirectBilling = "location-update-direct-billing",
  UpdateDoNotDeliver = "location-update-do-not-deliver",
}

export enum GroupAction {
  UpdatePage = "group-update-page",
  UpdatePerPage = "group-update-per-page",
  UpdateStatus = "group-update-status",
  UpdateKeyword = "group-update-keyword",
}

export type UserFiltersState = {
  page: number;
  status?: UserStatus;
  keyword?: string;
  group?: string;
  distributor?: string;
  role?: UserRole;
};

export enum UserAction {
  UpdateStatus = "user-update-status",
  UpdateKeyword = "user-update-keyword",
  UpdateGroup = "user-update-group",
  UpdateDistributor = "user-update-distributor",
  UpdateRole = "user-update-role",
}

export type InvoiceFilters =
  | SortFilters & {} & Omit<InvoiceFiltersState, "page">;

export type InvoiceFiltersState = {
  page: number;
  invoiceId?: string;
  billToId?: string;
  billToType?: string;
  status?: InvoiceStatus;
  keyword?: string;
  fromDate?: Date;
  toDate?: Date;
};

export enum InvoiceAction {
  UpdateInvoiceId = "invoice-update-invoice-id",
  UpdateStatus = "invoice-update-status",
  UpdateBillToId = "invoice-update-bill-to-id",
  UpdateBillToType = "invoice-update-bill-to-type",
  UpdateKeyword = "invoice-update-keyword",
  UpdateFromDate = "invoice-update-from-date",
  UpdateToDate = "invoice-update-to-date",
}
