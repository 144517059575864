import { QueryClient } from "@tanstack/react-query";
import getQueryKeys from "utils/get-query-keys";
import { LoaderFunction } from "react-router-dom";

import search from "algolia/search";

// types
import { Group, GroupFilters, GroupFiltersState } from "types/data";

export type QueryReturn = {
  pageData: (Group & {
    readonly objectID: string;
  })[];
  hasMore: boolean;
  count: number;
};

export type LoaderReturn = {
  groups: QueryReturn;
  params: GroupFiltersState;
};

const keys = getQueryKeys("groups");

function getAlgoliaQueryKeys(page: number, perPage: number, filters?: any) {
  return filters
    ? keys.list(page, perPage, filters)
    : keys.lists(page, perPage);
}

export const listGroupsQuery = (
  filters: GroupFilters,
  page = 0,
  hitsPerPage = 25
) => {
  const queryKey = getAlgoliaQueryKeys(page, hitsPerPage, filters);
  const query = filters?.keyword || "";
  const algoliaFilterArray = [];

  if (filters?.status && ["active", "inactive"].includes(filters.status)) {
    const activeValue = filters?.status === "active";
    algoliaFilterArray.push("active:" + activeValue);
  }

  const algoliaFilters = algoliaFilterArray.join(" AND ");
  return {
    queryKey,
    queryFn: async (): Promise<QueryReturn> => {
      return search<Group>("groups", query, {
        filters: algoliaFilters,
        page,
        hitsPerPage,
      });
    },
  };
};

export const loader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    const url = new URL(request.url);
    const keyword = url.searchParams.get("q") || undefined;
    const page = Number(url.searchParams.get("page"));
    const perPage = Number(url.searchParams.get("perPage")) || 30;
    const status = url.searchParams.get("status") || "any";
    const filters: GroupFilters = {
      keyword,
      status,
    };
    const query = listGroupsQuery(filters, page, perPage);
    const groups =
      queryClient.getQueryData<QueryReturn>(query.queryKey) ??
      (await queryClient.fetchQuery<QueryReturn>(query));
    // ⬇️ return data or fetch it
    return {
      groups,
      params: {
        ...filters,
        page,
        perPage,
      },
    };
  };
