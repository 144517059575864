import algoliasearch from "algoliasearch/lite";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID!,
  process.env.REACT_APP_ALGOLIA_API_KEY!
);

type RequestOptions = {
  filters?: string;
  page?: number;
  hitsPerPage?: number;
};
export default async function search<T>(
  indexName: string,
  query: string,
  requestOptions?: RequestOptions
) {
  const index = client.initIndex(indexName);
  const { hits, nbHits, nbPages } = await index.search<T>(
    query,
    requestOptions
  );
  const page = requestOptions?.page || 0;
  return { pageData: hits, hasMore: page + 1 < nbPages, count: nbHits };
}
