import React, { useRef } from "react";
import {
  useQuery,
  useQueryClient,
  QueryKey,
  keepPreviousData,
} from "@tanstack/react-query";
import {
  startAfter,
  endBefore,
  limitToLast,
  limit,
  query,
  DocumentData,
  onSnapshot,
  orderBy,
  DocumentReference,
  QueryDocumentSnapshot,
  Query,
  getCountFromServer,
} from "firebase/firestore";
import type { QueryConstraint, CollectionReference } from "firebase/firestore";

type ResponseType<T> = {
  pageData: T[];
  hasMore: boolean;
  count: number;
};
const PAGE_SIZE = 25;
export function useRealTimeQuery<Data = DocumentData>(
  firebasePathKey: QueryKey,
  collectionRef: CollectionReference<DocumentData> | Query<DocumentData>,
  queryConstraints?: QueryConstraint[],
  page = 0,
  perPage = PAGE_SIZE
) {
  const queryClient = useQueryClient();
  const currentPageRef = useRef(0);
  const snapshotStartRef = useRef<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();
  const snapshotEndRef = useRef<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();

  React.useEffect(() => {
    if (!queryConstraints) {
      return;
    }
    const contraints =
      queryConstraints && queryConstraints.length
        ? [...queryConstraints]
        : [orderBy("lastUpdated", "desc")];
    if (page === 0) {
      snapshotStartRef.current = undefined;
      snapshotEndRef.current = undefined;
      currentPageRef.current = 0;
    }
    const isNext = currentPageRef.current <= page;
    const actualPageSize = isNext ? perPage + 1 : perPage;
    console.log({
      actualPageSize,
      page,
      currentPage: currentPageRef.current,
      perPage,
    });

    if (page === currentPageRef.current + 1 && snapshotEndRef.current) {
      contraints.push(startAfter(snapshotEndRef.current));
      contraints.push(limit(actualPageSize));
    } else if (page === currentPageRef.current - 1 && page > 0) {
      console.log({ snapshotStart: snapshotStartRef.current });
      contraints.push(endBefore(snapshotStartRef.current));
      contraints.push(limitToLast(perPage));
    } else {
      contraints.push(limit(actualPageSize));
    }
    currentPageRef.current = page;
    const countQuery = query(collectionRef, ...queryConstraints);

    const q = query(collectionRef, ...contraints);
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let rowCount = NaN;
      if (queryConstraints.length > 0) {
        const countSnapshot = await getCountFromServer(countQuery);
        rowCount = countSnapshot.data().count;
      }
      const docs: Data[] = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data() as Data);
      });
      const pageData = docs.slice(
        0,
        Math.min(perPage, querySnapshot.docs.length)
      );
      const hasMore = !isNext || perPage < querySnapshot.docs.length;

      queryClient.setQueryData(firebasePathKey, {
        pageData,
        hasMore,
        count: rowCount,
      });
      console.log("useRealTimeQuery Response: ", docs);
      snapshotStartRef.current = querySnapshot.docs[0];
      snapshotEndRef.current = querySnapshot.docs[pageData.length - 1];
      console.log({ hasMore });
      return () => unsubscribe();
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, queryConstraints, firebasePathKey, page, perPage]);

  return useQuery({
    queryKey: firebasePathKey,
    queryFn: () => new Promise<ResponseType<Data>>(() => {}),
    placeholderData: keepPreviousData,
  });
}

export function useRealTimeDoc<Data = DocumentData>(
  firebasePathKey: QueryKey,
  query: DocumentReference
) {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!firebasePathKey) {
      return;
    }
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      const response = querySnapshot.data();

      queryClient.setQueryData(firebasePathKey, response);
      console.log("useRealTimeDoc Response: ", response);
      return () => unsubscribe();
    });

    return () => unsubscribe();
  }, [queryClient, query, firebasePathKey]);

  return useQuery({
    queryKey: firebasePathKey,
    queryFn: () => new Promise<Data>(() => {}),
  });
}
