import { UserRole, UserStatus, User } from "types/data";
import type { DocumentData } from "firebase/firestore";
import {
  storeOwnerNavConfig,
  adminNavConfig,
  dispatcherNavConfig,
  driverNavConfig,
  adminFleetNavConfig,
  distributorNavConfig,
} from "layouts/dashboard/navbar/NavConfig";

const navConfigMap = {
  [UserRole.Admin]: adminNavConfig,
  [UserRole.StoreOwner]: storeOwnerNavConfig,
  [UserRole.Driver]: driverNavConfig,
  [UserRole.Dispatcher]: dispatcherNavConfig,
  [UserRole.Distributor]: distributorNavConfig,
  [UserRole.Unknown]: () => [],
};
export function isPending(profile?: DocumentData | null): boolean {
  return profile?.status === UserStatus.Pending;
}

export function isAdmin(profile?: DocumentData | null): boolean {
  return profile?.role === UserRole.Admin;
}

export function getRole(profile?: User | null): UserRole {
  return profile?.role || UserRole.Unknown;
}

export function hasAccessBasedOnRole(
  userRole: UserRole,
  requiredRoles?: UserRole[]
): boolean {
  if (userRole === UserRole.Unknown) {
    return false;
  }
  if (requiredRoles === undefined) {
    return true;
  }
  return requiredRoles.includes(userRole);
}

export function getNavConfig(profile: User | null) {
  const isFleetManager = window.location.pathname.includes("/fleet");
  if (!profile) {
    return [];
  }
  if (isFleetManager && profile.role === UserRole.Admin) {
    return adminFleetNavConfig(profile);
  }
  return navConfigMap[profile.role](profile);
}
