import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import { WhereFilterOp } from "firebase/firestore";
// utils
import { fToNow } from "utils/formatTime";

import { PATH_DASHBOARD } from "routes/paths";

// components
import Iconify from "components/Iconify";
import Scrollbar from "components/Scrollbar";
import MenuPopover from "components/MenuPopover";
import { IconButtonAnimate } from "components/animate";
import { useList as useOrders } from "hooks/queries/useOrders";
import useLocalStorage from "hooks/useLocalStorage";
import { OrderFilters, OrderStatus } from "types/data";
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

type NotificationType = "orders" | "users";
type Props = {
  group?: string;
  distributor?: string;
  driver?: string;
};
export default function NotificationsPopoverNonAdmin({
  group,
  distributor,
  driver,
}: Props) {
  const navigate = useNavigate();
  const { profile } = useAuth();
  const [readNofications, setReadNotifications] = useLocalStorage<string[]>(
    "notifications",
    []
  );

  const orderFilters = useMemo<OrderFilters>(() => {
    const initialFilters: { rawQuery?: [string, WhereFilterOp, any][] } =
      profile?.id
        ? {
            rawQuery: [["createdBy", "!=", profile?.id]],
          }
        : ({} as const);
    if (driver) {
      return {
        status: OrderStatus.Dispatched,
        driver,
        orderBy: "created",
      };
    }
    if (distributor) {
      return {
        ...initialFilters,
        status: OrderStatus.Pending,
        distributor,
        orderBy: "created",
      };
    }

    if (group) {
      return {
        ...initialFilters,
        status: OrderStatus.Pending,
        group,
        orderBy: "created",
      };
    }
    return {
      status: OrderStatus.Pending,
      orderBy: "created",
    };
  }, [group, distributor, driver, profile?.id]);

  const orderQuery = useOrders(orderFilters, 0, 10);

  const pendingOrders = orderQuery?.data;

  const notifications =
    pendingOrders?.pageData
      ?.map((order) => {
        return {
          id: order.id,
          title: "New order",
          description: order.locationName,
          avatar: null,
          type: "orders" as NotificationType,
          lastUpdated: order.lastUpdated?.toDate() || new Date(),
          isUnRead: !readNofications || !readNofications.includes(order.id),
        };
      })
      .sort((a, b) => {
        return Number(b.lastUpdated) - Number(a.lastUpdated);
      }) || [];

  const totalUnRead =
    notifications?.filter((item) => item.isUnRead === true).length || 0;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = (type?: NotificationType) => {
    let ids: string[] = [];
    if (type) {
      ids = notifications
        ?.filter((notification) => notification.type === type)
        .map((notification) => notification.id);
    }
    setReadNotifications(ids);
  };

  const viewNotification = (id: string, type: NotificationType) => {
    handleClose();
    const currentRead = readNofications ? readNofications : [];
    setReadNotifications([...currentRead, id]);
    navigate(PATH_DASHBOARD[type].view(id));
  };

  const viewAllNotifications = (type: NotificationType) => {
    handleClose();
    handleMarkAllAsRead(type);
    navigate(PATH_DASHBOARD[type].list);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread notifications
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={() => handleMarkAllAsRead()}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 400 } }}>
          <List disablePadding>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                viewNotification={viewNotification}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        {notifications.length > 0 && (
          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              disableRipple
              onClick={() => viewAllNotifications("orders")}
            >
              View pending orders
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: NotificationType;
  lastUpdated: Date;
  isUnRead: boolean;
};

function NotificationItem({
  notification,
  viewNotification,
}: {
  notification: NotificationItemProps;
  viewNotification: (id: string, type: NotificationType) => void;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={() => {
        viewNotification(notification.id, notification.type);
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.lastUpdated)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === "users") {
    return {
      avatar: <PersonOutlinedIcon />,
      title,
    };
  }
  if (notification.type === "orders") {
    return {
      avatar: <ShoppingCartCheckoutOutlinedIcon />,
      title,
    };
  }

  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
