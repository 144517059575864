// routes
import { PATH_DASHBOARD } from "routes/paths";
// components
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import OilBarrelOutlinedIcon from "@mui/icons-material/OilBarrelOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PropaneOutlinedIcon from "@mui/icons-material/PropaneOutlined";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { User } from "types/data";
// ----------------------------------------------------------------------

const ICONS = {
  home: <InsertChartOutlinedTwoToneIcon />,
  user: <PersonOutlinedIcon />,
  invoice: <ReceiptOutlinedIcon />,
  analytics: <AutoGraphOutlinedIcon />,
  dashboard: <SpeedOutlinedIcon />,
  location: <LocalGasStationOutlinedIcon />,
  group: <StoreOutlinedIcon />,
  distributor: <CorporateFareOutlinedIcon />,
  source: <EvStationOutlinedIcon />,
  driver: <BadgeOutlinedIcon />,
  product: <OilBarrelOutlinedIcon />,
  order: <ShoppingCartCheckoutOutlinedIcon />,
  tanker: <PropaneOutlinedIcon />,
  transaction: <ReceiptLongOutlinedIcon />,
};

export const adminFleetNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [
      { title: "home", path: PATH_DASHBOARD.fleet.list, icon: ICONS.home },
    ],
  },
];

export const adminNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [{ title: "home", path: PATH_DASHBOARD.root, icon: ICONS.home }],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Fuel Manager",
    items: [
      // Orders
      {
        title: "orders",
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.order,
      },
      // Transactions
      {
        title: "transactions",
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.transaction,
      },
      // Invoices
      {
        title: "invoices",
        path: PATH_DASHBOARD.invoices.root,
        icon: ICONS.invoice,
      },
      // Locations
      {
        title: "locations",
        path: PATH_DASHBOARD.locations.root,
        icon: ICONS.location,
      },
      // Groups
      {
        title: "groups",
        path: PATH_DASHBOARD.groups.root,
        icon: ICONS.group,
      },
      // Users
      {
        title: "users",
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
      },
      // Drivers
      {
        title: "drivers",
        path: PATH_DASHBOARD.drivers.root,
        icon: ICONS.driver,
      },
      // Distributors
      {
        title: "distributors",
        path: PATH_DASHBOARD.distributors.root,
        icon: ICONS.distributor,
      },

      // Sources
      {
        title: "sources",
        path: PATH_DASHBOARD.sources.root,
        icon: ICONS.source,
      },
      // Products
      {
        title: "products",
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.product,
      },
    ],
  },
];

export const dispatcherNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [{ title: "home", path: PATH_DASHBOARD.root, icon: ICONS.home }],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "orders",
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.order,
      },
      // Transactions
      {
        title: "transactions",
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.transaction,
      },
      // Invoices
      {
        title: "invoices",
        path: PATH_DASHBOARD.invoices.root,
        icon: ICONS.invoice,
      },
      // Drivers
      {
        title: "drivers",
        path: PATH_DASHBOARD.drivers.root,
        icon: ICONS.driver,
      },
    ],
  },
];

export const driverNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [{ title: "home", path: PATH_DASHBOARD.root, icon: ICONS.home }],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "orders",
        path: PATH_DASHBOARD.general.orders,
        icon: ICONS.order,
      },
    ],
  },
];
export const storeOwnerNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [{ title: "home", path: PATH_DASHBOARD.root, icon: ICONS.home }],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "orders",
        path: PATH_DASHBOARD.urlFactory.list("orders", {
          group: profile?.group || "",
        }),
        icon: ICONS.order,
      },
      {
        title: "locations",
        path: PATH_DASHBOARD.urlFactory.list("locations", {
          group: profile?.group || "",
        }),
        icon: ICONS.location,
      },
      {
        title: "invoices",
        path: PATH_DASHBOARD.urlFactory.list("invoices", {
          billToId: profile?.group || "",
          billToType: "group",
        }),
        icon: ICONS.invoice,
      },
    ],
  },
];

export const distributorNavConfig = (profile: User | null) => [
  {
    subheader: "Dashboard",
    items: [{ title: "home", path: PATH_DASHBOARD.root, icon: ICONS.home }],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "orders",
        path: PATH_DASHBOARD.urlFactory.list("orders", {
          distributor: profile?.distributor || "",
        }),
        icon: ICONS.order,
      },
      {
        title: "locations",
        path: PATH_DASHBOARD.urlFactory.list("locations", {
          distributor: profile?.distributor || "",
        }),
        icon: ICONS.location,
      },
      {
        title: "invoices",
        path: PATH_DASHBOARD.urlFactory.list("invoices", {
          billToId: profile?.distributor || "",
          billToType: "distributor",
        }),
        icon: ICONS.invoice,
      },
    ],
  },
];
