import * as React from "react";
// hooks
import useAuth from "hooks/useAuth";

// Utils
import { getRole, hasAccessBasedOnRole } from "utils/user";
import { UserRole } from "types/data";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "routes/paths";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  requiredRoles?: UserRole[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  requiredRoles,
  children,
}: RoleBasedGuardProp) {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const hasAccess = hasAccessBasedOnRole(getRole(profile), requiredRoles);
  console.log({ hasContent, hasAccess });
  React.useEffect(() => {
    if (!hasAccess && hasContent) {
      return navigate(PATH_PAGE.page403);
    }
  }, [hasAccess, hasContent, navigate]);

  if (!hasAccess) {
    return null;
  }

  return <>{children}</>;
}
