import { Link as RouterLink, useLocation } from "react-router-dom";

// @mui
import { Stack, Button, Typography } from "@mui/material";
// hooks
import useAuth from "hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "routes/paths";
// components
import Image from "components/Image";
// images
import tankers from "img/shared/tankers.jpeg";
import station from "img/shared/gas-station.png";
import { useMemo } from "react";

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();
  const location = useLocation();
  const isFleetgManager = location.pathname.includes("/fleet");

  const orderManagerContent = useMemo(
    () => (
      <>
        <Image src={station} />

        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.displayName}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", whiteSpace: "pre-line" }}
        >
          Use the below link to switch to The Fleet Manager
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to={PATH_DASHBOARD.fleet.list}
        >
          Fleet Manager
        </Button>
      </>
    ),
    [user?.displayName]
  );

  const fleetManagerContent = useMemo(
    () => (
      <>
        <Image src={tankers} />

        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.displayName}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", whiteSpace: "pre-line" }}
        >
          Use the below link to switch to The Fuel Manager
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to={PATH_DASHBOARD.root}
        >
          Fuel Manager
        </Button>
      </>
    ),
    [user?.displayName]
  );

  const content = isFleetgManager ? fleetManagerContent : orderManagerContent;
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: "center",
        display: "block",
      }}
    >
      {content}
    </Stack>
  );
}
