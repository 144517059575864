import { QueryClient } from "@tanstack/react-query";
import getQueryKeys from "utils/get-query-keys";
import { LoaderFunction } from "react-router-dom";

import search from "algolia/search";

// types
import { Location, LocationFilters, LocationFiltersState } from "types/data";

export type QueryReturn = {
  pageData: (Location & {
    readonly objectID: string;
  })[];
  hasMore: boolean;
  count: number;
};

export type LoaderReturn = {
  locations: QueryReturn;
  params: LocationFiltersState;
};

const keys = getQueryKeys("locations");

function getAlgoliaQueryKeys(page: number, perPage: number, filters?: any) {
  return filters
    ? keys.list(page, perPage, filters)
    : keys.lists(page, perPage);
}

export const listLocationsQuery = (
  filters: LocationFilters,
  page = 0,
  hitsPerPage = 25
) => {
  const queryKey = getAlgoliaQueryKeys(page, hitsPerPage, filters);
  const query = filters?.keyword || "";
  const algoliaFilterArray = [];

  if (filters?.status && ["active", "inactive"].includes(filters.status)) {
    const activeValue = filters?.status === "active";
    algoliaFilterArray.push("active:" + activeValue);
  }

  if (filters?.group) {
    algoliaFilterArray.push("group:" + filters.group);
  }
  if (filters?.distributor) {
    if (filters?.subJobber) {
      algoliaFilterArray.push("subJobber:" + filters.distributor);
    } else {
      algoliaFilterArray.push("distributor:" + filters.distributor);
    }
  }
  if (filters?.keepFull) {
    algoliaFilterArray.push("keepFull:" + filters.keepFull);
  }

  if (filters?.directBilling) {
    algoliaFilterArray.push("directBilling:" + filters.directBilling);
  }

  if (filters?.doNotDeliver) {
    algoliaFilterArray.push("doNotDeliver:" + filters.doNotDeliver);
  }

  const algoliaFilters = algoliaFilterArray.join(" AND ");
  return {
    queryKey,
    queryFn: async (): Promise<QueryReturn> => {
      return search<Location>("locations", query, {
        filters: algoliaFilters,
        page,
        hitsPerPage,
      });
    },
  };
};

export const loader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    const url = new URL(request.url);
    const keyword = url.searchParams.get("q") || undefined;
    const page = Number(url.searchParams.get("page"));
    const perPage = Number(url.searchParams.get("perPage")) || 30;
    const status = url.searchParams.get("status") || "";
    const group = url.searchParams.get("group") || undefined;
    const distributor = url.searchParams.get("distributor") || undefined;
    const keepFull = url.searchParams.get("keepFull") === "on";
    const directBilling = url.searchParams.get("directBilling") === "on";
    const doNotDeliver = url.searchParams.get("doNotDeliver") === "on";
    const subJobber = url.searchParams.get("subJobber") === "on";
    const filters: LocationFilters = {
      keyword,
      status,
      group,
      distributor,
      directBilling,
      keepFull,
      doNotDeliver,
      subJobber,
    };
    const query = listLocationsQuery(filters, page, perPage);
    const locations =
      queryClient.getQueryData<QueryReturn>(query.queryKey) ??
      (await queryClient.fetchQuery<QueryReturn>(query));
    // ⬇️ return data or fetch it
    return {
      locations,
      params: {
        ...filters,
        page,
        perPage,
      },
    };
  };
