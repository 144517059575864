import {
  collection,
  where,
  orderBy,
  Timestamp,
  getAggregateFromServer,
  count,
  sum,
  query,
} from "firebase/firestore";
import { DB } from "backend/firebase";
import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "utils/get-query-keys";
import { isDateValid } from "utils/formatTime";
import { useRealTimeQuery } from "./useRealTimeQuery";
import { useMemo } from "react";

import { Order, OrderFilters, AggregateFilters } from "types/data";

const queryRef = collection(DB, "orders");

const keys = getQueryKeys("orders");

export function useList(filters: OrderFilters, page = 0, rowsPerPage = 25) {
  const key = useMemo(() => {
    return filters
      ? keys.list(page, rowsPerPage, filters)
      : keys.lists(page, rowsPerPage);
  }, [page, filters, rowsPerPage]);

  const queryConstraints = useMemo(() => {
    const nameWhere = [];
    if (filters?.status) {
      nameWhere.push(where("status", "==", filters?.status));
    }
    // TODO: re-enable keyword search
    // const keywords = getKeywordArray(filters?.keyword);
    // if (filters?.keyword && keywords.length) {
    //   nameWhere.push(where("keywords", "array-contains-any", keywords));
    // }

    if (filters?.rawQuery) {
      for (const row of filters.rawQuery) {
        const fieldName = row[0];
        nameWhere.push(where(...row));
        filters.orderBy = fieldName;
      }
    }
    const searchByLocation = !!filters?.location;
    const searchByGroup = !!filters?.group;
    const searchByDistributor = !!filters?.distributor;
    if (searchByLocation) {
      nameWhere.push(where("location", "==", filters.location));
    }

    if (searchByGroup) {
      nameWhere.push(where("group", "==", filters.group));
    }
    if (searchByDistributor) {
      if (filters?.subJobber) {
        nameWhere.push(where("subJobber", "==", filters.distributor));
      } else {
        nameWhere.push(where("distributor", "==", filters.distributor));
      }
    }
    if (filters?.dispatchConfirmed) {
      nameWhere.push(
        where("dispatchConfirmed", "==", filters.dispatchConfirmed)
      );
    }
    if (filters?.driver) {
      nameWhere.push(where("driver", "==", filters.driver));
    }

    const requestedDateFrom = filters?.requestedDateFrom;
    const requestedDateTo = filters?.requestedDateTo;

    if (isDateValid(requestedDateFrom)) {
      nameWhere.push(
        where("requestedDate", ">=", Timestamp.fromDate(requestedDateFrom))
      );
      if (filters) {
        filters.orderBy = "requestedDate";
      }
    }
    if (isDateValid(requestedDateTo)) {
      nameWhere.push(
        where("requestedDate", "<=", Timestamp.fromDate(requestedDateTo))
      );
      if (filters) {
        filters.orderBy = "requestedDate";
      }
    }

    if (filters?.orderBy) {
      nameWhere.push(
        orderBy(filters?.orderBy, filters?.order === "asc" ? "asc" : "desc")
      );
    }
    return nameWhere;
  }, [filters]);

  console.log({ filters, page, rowsPerPage });
  return useRealTimeQuery<Order>(
    key,
    queryRef,
    queryConstraints,
    page,
    rowsPerPage
  );
}

export function useCounter(filters: AggregateFilters) {
  const key = useMemo(() => {
    return filters ? keys.count(filters) : keys.count({});
  }, [filters]);

  const queryConstraints = useMemo(() => {
    const nameWhere = [];
    const searchByLocation = !!filters?.location;
    const searchByGroup = !!filters?.group && !searchByLocation;
    const searchByDistributor = !!filters?.distributor && !searchByLocation;
    if (searchByLocation) {
      nameWhere.push(where("location", "==", filters.location));
    }

    if (searchByGroup) {
      nameWhere.push(where("group", "==", filters.group));
    }
    if (searchByDistributor) {
      nameWhere.push(where("distributor", "==", filters.distributor));
    }
    if (filters?.driver) {
      nameWhere.push(where("driver", "==", filters.driver));
    }
    const createdFrom = filters?.createdFrom;
    const createdTo = filters?.createdTo;

    if (isDateValid(createdFrom)) {
      nameWhere.push(where("created", ">=", Timestamp.fromDate(createdFrom)));
    }
    if (isDateValid(createdTo)) {
      nameWhere.push(where("created", "<=", Timestamp.fromDate(createdTo)));
    }
    return nameWhere;
  }, [filters]);

  console.log({ filters });
  return useQuery({
    queryKey: key,

    queryFn: async () => {
      const aggregateQuery = query(queryRef, ...queryConstraints);

      const aggregatePartOne = getAggregateFromServer(aggregateQuery, {
        count: count(),
        totalFuelMidgrade: sum("fuelMidgrade"),
        totalFuelPremium: sum("fuelPremium"),
        totalFuelRegular: sum("fuelRegular"),
        totalFuelE85: sum("fuelE85"),
      });
      const aggregatePartTwo = getAggregateFromServer(aggregateQuery, {
        totalFuelBioDiesel: sum("fuelBioDiesel"),
        totalFuelKerosene: sum("fuelKerosene"),
        totalFuelDiesel: sum("fuelDiesel"),
      });
      const [snapshot, snapshot2] = await Promise.all([
        aggregatePartOne,
        aggregatePartTwo,
      ]);
      return { ...snapshot.data(), ...snapshot2.data() };
    },
  });
}
