import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------
import largeLogo from "img/united-logistics-high-resolution-logo-color-on-transparent-background.png";
import smallLogo from "img/united-logistics-website-favicon-color.png";
import Image from "./Image";
import { PATH_DASHBOARD } from "routes/paths";
import useAuth from "hooks/useAuth";

interface Props extends BoxProps {
  disabledLink?: boolean;
  large?: boolean;
}

export default function Logo({
  large = true,
  disabledLink = false,
  sx,
}: Props) {
  const { user } = useAuth();
  const location = useLocation();
  const isDashboard = location.pathname === PATH_DASHBOARD.root;
  const isHome = location.pathname === "/";
  const homeLink = user ? PATH_DASHBOARD.root : "/";
  const noLink = (user && isDashboard) || (!user && isHome) || disabledLink;
  const logo = (
    <Box sx={{ width: large ? 200 : 65, ...sx }}>
      <Image src={large ? largeLogo : smallLogo} alt="United Logistics" />
    </Box>
  );

  if (noLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={homeLink}>{logo}</RouterLink>;
}
