import { Outlet } from "react-router-dom";
// @mui
import { Box, Stack } from "@mui/material";

import Copyright from "components/shared/Copyright";

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <Box sx={{ flexGrow: 1 }} />
      <Outlet />
      <Box sx={{ flexGrow: 1 }} />
      <Copyright />
    </Stack>
  );
}
