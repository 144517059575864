import { Order, Transaction } from "types/data";

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFuelInfoFromOrderOrTransaction(doc?: Order | Transaction) {
  return [
    {
      label: "Regular",
      value: doc?.fuelRegular || 0,
      hasValue: doc?.fuelRegular && doc?.fuelRegular > 0,
    },
    {
      label: "Midgrade",
      value: doc?.fuelMidgrade || 0,
      hasValue: doc?.fuelMidgrade && doc.fuelMidgrade > 0,
    },
    {
      label: "Premium",
      value: doc?.fuelPremium || 0,
      hasValue: doc?.fuelPremium && doc?.fuelPremium > 0,
    },
    {
      label: "E85",
      value: doc?.fuelE85 || 0,
      hasValue: doc?.fuelE85 && doc?.fuelE85 > 0,
    },
    {
      label: "Diesel",
      value: doc?.fuelDiesel || 0,
      hasValue: doc?.fuelDiesel && doc?.fuelDiesel > 0,
    },
    {
      label: "BioDiesel",
      value: doc?.fuelBioDiesel || 0,
      hasValue: doc?.fuelBioDiesel && doc?.fuelBioDiesel > 0,
    },
    {
      label: "Kerosene",
      value: doc?.fuelKerosene || 0,
      hasValue: doc?.fuelKerosene && doc?.fuelKerosene > 0,
    },
    {
      label: "Total",
      value: doc?.totalFuel || 0,
      hasValue: doc?.totalFuel && doc?.totalFuel > 0,
    },
  ].filter((item) => item.hasValue);
}
