import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet } from "react-router-dom";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "components/settings";
import { ChartStyle } from "components/chart";
import ScrollToTop from "components/ScrollToTop";
import { ProgressBarStyle } from "components/ProgressBar";
import NotistackProvider from "components/NotistackProvider";
import MotionLazyContainer from "components/animate/MotionLazyContainer";
import { ConfirmProvider } from "material-ui-confirm";

// config
import { queryClient } from "config";
//-----------------

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ConfirmProvider
            defaultOptions={{
              confirmationButtonProps: { autoFocus: true },
            }}
          >
            <ThemeSettings>
              <NotistackProvider>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <Outlet />
              </NotistackProvider>
            </ThemeSettings>
          </ConfirmProvider>
        </ThemeProvider>
      </MotionLazyContainer>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
