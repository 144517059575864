import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// hooks
import useAuth from "hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "routes/paths";
// components
import MyAvatar from "components/MyAvatar";
import { UserRole } from "types/data";
import { capitalize } from "utils/shared";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { profile } = useAuth();
  let link = "";
  if (profile?.distributor && profile?.role === UserRole.Distributor) {
    link = PATH_DASHBOARD.distributors.view(profile?.distributor);
  }
  if (profile?.group && profile?.role === UserRole.StoreOwner) {
    link = PATH_DASHBOARD.groups.view(profile?.group);
  }

  const content = (
    <>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {profile?.displayName}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {profile?.role ? capitalize(profile.role) : "n/a"}
          </Typography>
        </Box>
      </RootStyle>
    </>
  );

  if (!link) {
    return content;
  }
  return (
    <Link underline="none" color="inherit" component={RouterLink} to={link}>
      {content}
    </Link>
  );
}
