// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import {
  getFirestore /* connectFirestoreEmulator */,
} from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { FIREBASE_CONFIG, RECAPTCHA_SITE_KEY } from "config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const firebaseApp = initializeApp(FIREBASE_CONFIG);
// Enable App check in debig mode (localhost)
if (window.location.hostname === "localhost") {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = RECAPTCHA_SITE_KEY;
}
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY!),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
export const AUTH = getAuth(firebaseApp);
export const DB = getFirestore(firebaseApp);
export const STORAGE = getStorage(firebaseApp);
export const FUNCTIONS = getFunctions(firebaseApp);

// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(DB, "localhost", 8080);
// }
