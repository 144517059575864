import { collection, where, orderBy } from "firebase/firestore";
import { DB } from "backend/firebase";

import { useRealTimeQuery } from "./useRealTimeQuery";
import { useMemo } from "react";
import { User, UserFilters } from "types/data";
import { getKeywordArray } from "utils/search";
import getQueryKeys from "utils/get-query-keys";
import useAuth from "hooks/useAuth";

const usersRef = collection(DB, "users");

export const keys = getQueryKeys("users");

//const q = query(usersRef);
export default function useUserList(
  filters: UserFilters,
  page = 0,
  rowsPerPage = 25
) {
  const { profile } = useAuth();
  const key = useMemo(() => {
    return filters
      ? keys.list(page, rowsPerPage, filters)
      : keys.lists(page, rowsPerPage);
  }, [page, filters, rowsPerPage]);

  const queryConstraints = useMemo(() => {
    const nameWhere = [];
    const keywords = getKeywordArray(filters?.keyword);
    if (filters?.keyword && keywords.length) {
      nameWhere.push(where("keywords", "array-contains-any", keywords));
    }

    if (filters?.status !== undefined) {
      nameWhere.push(where("status", "==", filters?.status));
    }
    if (filters?.role !== undefined) {
      nameWhere.push(where("role", "==", filters?.role));
    }

    if (filters?.group) {
      nameWhere.push(where("group", "==", filters.group));
    }

    nameWhere.push(where("id", "!=", profile?.id));
    nameWhere.push(orderBy("id", filters?.order === "asc" ? "asc" : "desc"));
    nameWhere.push(
      orderBy("created", filters?.order === "asc" ? "asc" : "desc")
    );

    return nameWhere;
  }, [filters, profile?.id]);
  console.log({ filters, page, rowsPerPage });
  return useRealTimeQuery<User>(
    key,
    usersRef,
    queryConstraints,
    page,
    rowsPerPage
  );
}
