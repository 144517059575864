import * as React from "react";
import { Outlet } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";

// Components
import Logo from "components/Logo";
import Copyright from "components/shared/Copyright";

export default function AuthLayout() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo sx={{ mb: 1, mx: "auto" }} disabledLink />

        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon sx={{ color: "common.white" }} />
        </Avatar>
        <Outlet />
      </Box>
      <Copyright sx={{ mt: 2 }} hideLogo disabledLink />
    </Container>
  );
}
