import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
// Reject icon
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
// Accept Icon
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";

// utils
import { fToNow } from "utils/formatTime";

import { PATH_DASHBOARD } from "routes/paths";

// components
import Iconify from "components/Iconify";
import Scrollbar from "components/Scrollbar";
import MenuPopover from "components/MenuPopover";
import { IconButtonAnimate } from "components/animate";
import useUsers from "hooks/queries/useUsers";
import { useList as useOrders } from "hooks/queries/useOrders";
import useLocalStorage from "hooks/useLocalStorage";
import { UserFilters, UserStatus, OrderFilters, OrderStatus } from "types/data";
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

type NotificationType =
  | "orders"
  | "users"
  | "confirmed_orders"
  | "rejected_orders";
export default function NotificationsPopover() {
  const navigate = useNavigate();
  const { profile } = useAuth();
  const [readNofications, setReadNotifications] = useLocalStorage<string[]>(
    "notifications",
    []
  );
  const userFilters = useMemo<UserFilters>(() => {
    return { status: UserStatus.Pending, orderBy: "created" };
  }, []);

  const pendingOrdersFilters = useMemo<OrderFilters>(() => {
    return { status: OrderStatus.Pending, orderBy: "created" };
  }, []);

  const rejectedOrdersFilters = useMemo<OrderFilters>(() => {
    return {
      orderBy: "created",
      rawQuery: [["rejectedBy", "not-in", [profile?.id, ""]]],
    };
  }, [profile?.id]);

  const confirmedDispatchOrdersFilters = useMemo<OrderFilters>(() => {
    return {
      dispatchConfirmed: true,
      status: OrderStatus.Dispatched,
      orderBy: "created",
    };
  }, []);
  const userQuery = useUsers(userFilters, 0, 10);
  const pendingOrdersQuery = useOrders(pendingOrdersFilters, 0, 10);
  const rejectedOrdersQuery = useOrders(rejectedOrdersFilters, 0, 10);
  const confirmedOrdersQuery = useOrders(confirmedDispatchOrdersFilters, 0, 10);

  const pendingUsers = userQuery?.data;
  const pendingOrders = pendingOrdersQuery?.data;
  const rejectedOrders = rejectedOrdersQuery?.data;
  const confirmedOrders = confirmedOrdersQuery?.data;

  const userNotifications =
    pendingUsers?.pageData?.map((user) => {
      return {
        id: user.id,
        key: user.id,
        title: "New user",
        description: user.emailAddress,
        avatar: null,
        type: "users" as NotificationType,
        lastUpdated: user.lastUpdated.toDate(),
        isUnRead: !readNofications || !readNofications.includes(user.id),
      };
    }) || [];

  const pendingOrderNotifications =
    pendingOrders?.pageData?.map((order) => {
      const key = `${order.id}_pending`;
      return {
        id: order.id,
        key,
        title: "New order",
        description: order.locationName,
        avatar: null,
        type: "orders" as NotificationType,
        lastUpdated: order.lastUpdated?.toDate() || new Date(),
        isUnRead: !readNofications || !readNofications.includes(key),
      };
    }) || [];

  const rejectedOrderNotifications =
    rejectedOrders?.pageData?.map((order) => {
      const key = `${order.id}_rejected_by_${order.rejectedBy}`;
      return {
        id: order.id,
        key,
        title: "Rejected order",
        description: order.locationName,
        avatar: null,
        type: "rejected_orders" as NotificationType,
        lastUpdated: order.lastUpdated?.toDate() || new Date(),
        isUnRead: !readNofications || !readNofications.includes(key),
      };
    }) || [];
  const rconfirmedOrderNotifications =
    confirmedOrders?.pageData?.map((order) => {
      const key = `${order.id}_dispatch_confirmed_${order.driver}`;
      return {
        id: order.id,
        key,
        title: "Dispatch Confirmed",
        description: order.locationName,
        avatar: null,
        type: "confirmed_orders" as NotificationType,
        lastUpdated: order.lastUpdated?.toDate() || new Date(),
        isUnRead: !readNofications || !readNofications.includes(key),
      };
    }) || [];

  const notifications = [
    ...userNotifications,
    ...pendingOrderNotifications,
    ...rejectedOrderNotifications,
    ...rconfirmedOrderNotifications,
  ].sort((a, b) => {
    return Number(b.lastUpdated) - Number(a.lastUpdated);
  });
  const totalUnRead =
    notifications?.filter((item) => item.isUnRead === true).length || 0;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = (type?: NotificationType) => {
    let keys: string[] = [];
    if (type) {
      keys = notifications
        ?.filter((notification) => notification.type === type)
        .map((notification) => notification.key);
    } else {
      keys = notifications.map((notification) => notification.key);
    }
    setReadNotifications(keys);
  };

  const viewNotification = (
    id: string,
    key: string,
    type: NotificationType
  ) => {
    handleClose();
    const currentRead = readNofications ? readNofications : [];
    setReadNotifications([...currentRead, key]);
    const rootPath = type === "users" ? "users" : "orders";
    navigate(PATH_DASHBOARD[rootPath].view(id));
  };

  const viewAllNotifications = (type: NotificationType) => {
    handleClose();
    handleMarkAllAsRead(type);
    const rootPath = type === "users" ? "users" : "orders";
    navigate(PATH_DASHBOARD[rootPath].list);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread notifications
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={() => handleMarkAllAsRead()}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 400 } }}>
          <List disablePadding>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                viewNotification={viewNotification}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        {pendingOrderNotifications.length > 0 && (
          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              disableRipple
              onClick={() => viewAllNotifications("orders")}
            >
              View pending orders
            </Button>
          </Box>
        )}
        {userNotifications.length > 0 && (
          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              disableRipple
              onClick={() => viewAllNotifications("users")}
            >
              View pending users
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  key: string;
  title: string;
  description: string;
  avatar: string | null;
  type: NotificationType;
  lastUpdated: Date;
  isUnRead: boolean;
};

function NotificationItem({
  notification,
  viewNotification,
}: {
  notification: NotificationItemProps;
  viewNotification: (id: string, key: string, type: NotificationType) => void;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={() => {
        viewNotification(notification.id, notification.key, notification.type);
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.lastUpdated)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === "users") {
    return {
      avatar: <PersonOutlinedIcon />,
      title,
    };
  }
  if (notification.type === "orders") {
    return {
      avatar: <ShoppingCartCheckoutOutlinedIcon />,
      title,
    };
  }
  if (notification.type === "rejected_orders") {
    return {
      avatar: <ThumbDownOffAltOutlinedIcon />,
      title,
    };
  }
  if (notification.type === "confirmed_orders") {
    return {
      avatar: <ThumbUpOffAltOutlinedIcon />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
